export function organizeList(listData) {
  let list = listData.map((articles) => {
    let data = articles.data();
    return {
      id: articles.id,
      ...data,
    };
  });

  return list;
}
