import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    user: null,
    roles: [],
  },
  reducers: {
    setUser(state, action) {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    signOut(state, action) {
      state.user = null;
    },
  },
});

export const { setUser, signOut } = appSlice.actions;
export default appSlice.reducer;
