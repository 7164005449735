import { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getDoc, collection, doc } from "firebase/firestore";
import { FallingLines } from "react-loader-spinner";
import { BiCoffeeTogo } from "react-icons/bi";

import Header from "../../components/Header";
import SpaceItem from "../../components/SpaceItem";
import SectionTitle from "../../components/SectionTitle";

import { setUser } from "../../config/store/reducers/AppReducer";
import SpaceOptions from "../../constants/spacesOptions";
import { db } from "../../config/database";
import "./styles.css";

function Home() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Tapedin Space";
    getSpaces();
  }, []);

  const getSpaces = async () => {
    try {
      const ref = await getDoc(doc(collection(db, "users"), user.uid));
      const userData = ref.data();
      dispatch(
        setUser({
          name: userData.name,
          roles: userData.roles || [],
          photoURL: userData?.photoURL || null,
        })
      );
      setLoading(false);
    } catch (error) {}
  };

  const roles = SpaceOptions.filter((item) =>
    user?.roles?.find((itemRole) => itemRole == item.id)
  );

  if (loading) {
    return (
      <div className="homeContainer">
        <Header />
        <main className={`mainContainer  mainContainerLoading`}>
          <FallingLines
            color="#eef0f5"
            width="100"
            visible={true}
            ariaLabel="falling-lines-loading"
          />
        </main>
      </div>
    );
  }

  return (
    <div className="homeContainer">
      <Header />
      <main className="mainContainer">
        <SectionTitle
          title="Spaces"
          description="Aqui estão todos os seus grupos de spaces."
          icon={<BiCoffeeTogo size={40} />}
        />
        <div className="spacesContainer">
          {roles.map((item, index) => (
            <SpaceItem key={item.id} index={index} data={item} />
          ))}
        </div>
      </main>
    </div>
  );
}

export default Home;
