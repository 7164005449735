import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// Routes

import ProtectScreen from "./ProtectScreen";
import Login from "./Auth/Login";

import Home from "./Home";

import Cms from "./Cms";
import CreateArticle from "./Cms/CreateArticle";
import DraftsArticle from "./Cms/Drafts";

import Dev from "./Dev";
import Publish from "./Dev/Publish";

import Contents from "./Contents";

import getStoreConfig from "../config/store";
const { store, persistor } = getStoreConfig();

export default () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Pages />
      </PersistGate>
    </Provider>
  );
};

function Pages() {
  const user = useSelector((state) => state.app.user);

  const router = createBrowserRouter(
    !user
      ? [
          {
            path: "/",
            element: <ProtectScreen />,
          },
          {
            path: "/space/login",
            element: <Login />,
          },
        ]
      : [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "/cms",
            element: <Cms />,
          },
          {
            path: "/cms/create",
            element: <CreateArticle />,
          },
          {
            path: "/cms/drafts",
            element: <DraftsArticle />,
          },
          {
            path: "/dev",
            element: <Dev />,
            children: [
              {
                path: "publish",
                element: <Publish />,
                index: true,
              },
            ],
          },
          {
            path: "/contents",
            element: <Contents />,
          },
        ]
  );
  return <RouterProvider router={router} />;
}
