import React from "react";

import { BiUserCircle } from "react-icons/bi";

import "./styles.css";

export default function Input({ icon, style, containerStyle, ...rest }) {
  return (
    <div style={style} className={`inputItemContainer  ${containerStyle}`}>
      {icon && icon}
      <input
        {...rest}
        style={{
          marginLeft: !!icon ? 5 : 0,
        }}
      />
    </div>
  );
}
