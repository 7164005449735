import React, { useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../config/database";
import { useDispatch, useSelector } from "react-redux";

import { signOut as signOutUser } from "../../config/store/reducers/AppReducer";

import { BiUserCircle, BiArrowFromLeft, BiArrowBack } from "react-icons/bi";

import TapedinLogoName from "../../assets/logo.png";
import LogoBlack from "../../assets/black_logo.svg";

import "./styles.css";

export default function Header({ onBack, center }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.app.user);

  const [showsUserOptions, setShowsUserOptions] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
      dispatch(signOutUser());
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div className="headerContainer">
        {!onBack ? (
          <img className="my-2" style={{ width: 35 }} src={LogoBlack} />
        ) : (
          <div className="flex flex-row items-center">
            <BiArrowBack
              size={25}
              onClick={
                typeof onBack == "function" ? onBack : () => navigate("/")
              }
            />
            <img className="my-2 ml-4" style={{ width: 35 }} src={LogoBlack} />
          </div>
        )}
        {user && user.name ? (
          <div
            onClick={() => setShowsUserOptions(!showsUserOptions)}
            className="headerUserContainer"
          >
            <span>
              <span style={{ color: "var(--primary)" }}>Hello,</span>{" "}
              {user.name}
            </span>
            {!user?.photoURL ? (
              <BiUserCircle size={30} />
            ) : (
              <img
                className="w-[40px] h-[40px] object-cover rounded-xl"
                src={user?.photoURL}
              />
            )}
          </div>
        ) : (
          <div />
        )}
        <AnimatePresence>
          {showsUserOptions && (
            <motion.div
              exit={{ opacity: 0, y: -10 }}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="headerUserOptionsContainer"
            >
              <ul>
                <li onClick={() => handleSignOut()}>
                  <span>Sair</span>
                  <BiArrowFromLeft size={25} />
                </li>
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Separator />
    </>
  );
}

export const Separator = () => {
  return <div className="headerSeparator" />;
};
