import React from "react";

import { Oval } from "react-loader-spinner";

import "./styles.css";

export default function Button({ title, loading, containerStyle, ...rest }) {
  return (
    <button
      disabled={loading}
      className={`buttonItemContainer  ${containerStyle}`}
      {...rest}
    >
      {loading && (
        <Oval
          height={30}
          width={30}
          color="#ff945b"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ff945b"
          strokeWidth={5}
          strokeWidthSecondary={2}
        />
      )}
      {!loading && title}
    </button>
  );
}

export function MenuButton({ title, loading, containerStyle, ...rest }) {
  return (
    <button
      disabled={loading}
      className={`buttonItemContainer  ${containerStyle}`}
      {...rest}
    >
      {title}
    </button>
  );
}
