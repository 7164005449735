import React from "react";

import { motion } from "framer-motion";
import Button from "./Button";

export default function Dialog({
  visible,
  title = "Tem certeza?",
  description = "Clique em publicar para publicar o artigo.",
  onClose,
  onPress,
  confirmTitle = "Publicar",
  loading,
}) {
  if (!visible) return null;

  return (
    <motion.div
      className="absolute inset-0 z-1"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
    >
      <div
        className="w-full h-full flex justify-center items-center"
        style={{
          backgroundColor: "#00000050",
        }}
      >
        <div className="w-1/4 h-2/5 bg-white rounded-2xl">
          <div className="flex flex-col items-center p-12">
            <strong className="text-2xl font-semibold">{title}</strong>
            <p className="text-center mt-2 font-regular">{description}</p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <Button title={confirmTitle} onClick={onPress} loading={loading} />
            <Button
              title={"Cancelar"}
              containerStyle="mt-4 bg-white hover:bg-gray-200 hover:text-black"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
