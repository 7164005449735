import React, { useMemo } from "react";

import useGetPermission from "../../hooks/useGetPermission";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import SectionTitle from "../../components/SectionTitle";

import spacesOptions from "../../constants/spacesOptions";

export default function Contents() {
  const permission = useGetPermission("contents");

  const spaceItem = useMemo(
    () => spacesOptions.find((item) => item.id == "contents"),
    []
  );
  return (
    <Layout>
      <Header onBack />
      <main>
        <SectionTitle
          title="Contents"
          icon={spaceItem.icon}
          description="Conteúdos, reportagens e usuários"
        />
        <div className="mt-12"></div>
      </main>
    </Layout>
  );
}
