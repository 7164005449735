import { useEffect } from "react";

export default function ProtectScreen() {
  useEffect(() => {
    document.title = window.location.href;
    window.location.href = "https://tapedin.com.br";
  }, []);

  return null;
}
