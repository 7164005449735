import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";

import moment from "moment";

import { collection, doc, getDoc, setDoc, addDoc } from "firebase/firestore";
import { useNavigate, useSearchParams } from "react-router-dom";
import SimpleMDE from "react-simplemde-editor";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

import Header from "../../../components/Header";
import Button from "../../../components/Button";
import { FallingLines } from "react-loader-spinner";

import useGetPermission from "../../../hooks/useGetPermission";
import { db } from "../../../config/database";
import Dialog from "../../../components/Dialog";
import "easymde/dist/easymde.min.css";

var draftTimeout = null;

export default function Create() {
  const permission = useGetPermission("cms");

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [publishDialog, setPublishDialog] = useState(false);
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [slug, setSlug] = useState("");
  const [isTemplate, setIsTemplate] = useState(false);
  const [saving, setSaving] = useState(false);

  const [loadingDraft, setLoadingDraft] = useState(false);
  const [draftId, setDraftId] = useState(null);

  const getDraft = useCallback(async (draft) => {
    try {
      setLoadingDraft(true);
      const draftArticleRef = collection(db, "cms/blog/drafts");
      const draftData = (await getDoc(doc(draftArticleRef, draft))).data();

      if (!draftData) {
        throw "This article doesnt exists";
      }

      setValue(draftData.value);
      setTitle(draftData.title);
      setTags(draftData.tags);
      setSlug(draftData.slug);
      setIsTemplate(draftData.template);
    } catch (error) {
      alert(error);
    } finally {
      setLoadingDraft(false);
    }
  }, []);

  useEffect(() => {
    let draft = searchParams.get("draftId");
    if (draft && !draftId && permission) {
      setDraftId(draft);
      getDraft(draft);
    }
  }, [getDraft, permission]);

  const saveToDraft = async () => {
    try {
      const draftArticleRef = collection(db, "cms/blog/drafts");
      const date = moment().format("YYYY-MM-DD");

      setSaving(true);

      const data = {
        tags,
        title,
        slug,
        value,
        date,
        template: false,
      };

      if (!draftId) {
        const newDraft = await addDoc(draftArticleRef, data);
        setDraftId(newDraft.id);
      } else {
        await setDoc(doc(draftArticleRef, draftId), data, { merge: true });
      }
    } catch (error) {
      alert(error);
    } finally {
      setSaving(false);
    }
  };

  const onChange = useCallback((value) => {
    setValue(value);
  }, []);

  const handleNewArticle = async () => {
    try {
      setLoading(true);

      const gradient = Math.floor(Math.random() * 6);
      const geometry = Math.floor(Math.random() * 4);
      const slugFormat = slug.toLowerCase().replace(/ /g, "-");
      const tagsFormat = tags.split(",");
      const newArticleRef = collection(db, "cms/blog/articles");
      const date = moment().format("YYYY-MM-DD");
      let data = {
        gradient,
        geometry,
        tags: tagsFormat,
        date,
        title,
        views: 0,
        content: value,
      };

      if (draftId && !isTemplate) {
        data.draftId = draftId;
      }

      const existsref = await getDoc(doc(newArticleRef, slugFormat));

      if (existsref.exists()) {
        alert("Esse slug já existe");
        return;
      }

      await setDoc(doc(newArticleRef, slugFormat), data);
      window.open(`https://tapedin.com.br/blog/${slugFormat}`, "_blank");
      navigate("/cms");
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogVisible = () => {
    if (!value || !title || !tags || !slug) {
      alert("Ops, está faltando alguma coisa!");
      return;
    }
    setPublishDialog(true);
  };

  const options = useMemo(
    () => ({
      spellChecker: false,
      previewRender: (text, html) => {
        let string = ReactDOMServer.renderToString(
          <ReactMarkdown
            className="markdown"
            remarkPlugins={[remarkBreaks]}
            children={text}
          />
        );

        return string;
      },
    }),
    []
  );

  if (loadingDraft) {
    return (
      <>
        <div>
          <Header onBack={() => navigate("/cms")} />
          <main className="flex justify-center items-center h-[100vh]">
            <FallingLines
              color="#eef0f5"
              width="100"
              visible={true}
              ariaLabel="falling-lines-loading"
            />
          </main>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <Header onBack={() => navigate("/cms/drafts")} />
        <main>
          <div className="mt-8 flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="text-3xl placeholder:text-gray-400 break-words w-full"
                placeholder="Título do artigo"
                autoFocus
                multiple
              />
              <div className="flex flex-row items-center mt-4">
                <input
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  className="text-lg bg-gray-200 px-4 py-2 rounded-xl mt-2 placeholder:text-gray-400"
                  placeholder="Tags (Separar por vígular)"
                />
                <input
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  className="text-lg bg-gray-200 ml-4 px-4 py-2 rounded-xl mt-2 placeholder:text-gray-400"
                  placeholder="Slug (English)"
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              {saving ? (
                <span className="text-gray-600 mr-4">Saving to draft</span>
              ) : (
                <Button
                  onClick={() => saveToDraft()}
                  title="Save to draft"
                  containerStyle="w-40 bg-white mr-4"
                />
              )}
              <Button
                onClick={() => handleDialogVisible()}
                title="Publish"
                containerStyle="w-60"
              />
            </div>
          </div>
          <SimpleMDE
            className="mt-12"
            value={value}
            onChange={onChange}
            options={options}
            placeholder="Comece o artigo aqui!"
          />
        </main>
      </div>
      <Dialog
        visible={publishDialog}
        loading={loading}
        onPress={() => handleNewArticle()}
        onClose={() => setPublishDialog(false)}
      />
    </>
  );
}
