import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useSearchParams } from "react-router-dom";

import { BiUserCircle, BiLockAlt } from "react-icons/bi";

import Input from "../../../components/Input";
import Button from "../../../components/Button";

import { auth } from "../../../config/database";
import { setUser } from "../../../config/store/reducers/AppReducer";
import { spaceToken } from "../../../constants/spaceConfig";

import LogoBlack from "../../../assets/black_logo.svg";

import "./styles.css";

export default function Login() {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    let token = searchParams.get("token");

    if (!token || token != spaceToken) {
      window.location.href = "https://tapedin.com.br";
      return;
    }

    document.title = "Tapedin Space";
  }, []);

  const handleLogin = async () => {
    try {
      if (!email || !password) return;
      setLoading(true);
      const $email = `${email}@tapedin.com.br`;

      const response = await signInWithEmailAndPassword(auth, $email, password);
      const { uid, email: userEmail } = response.user;
      dispatch(
        setUser({
          uid,
          email: userEmail,
        })
      );

      window.location.href = "/";
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginContainer">
      <main className="mainLogin">
        <h1>
          <span
            style={{
              color: "#ff945b",
              fontWeight: 400,
            }}
          >
            Welcome
          </span>
          <br />
          to Space
        </h1>

        <div className="authContainer">
          <Input
            icon={<BiUserCircle size={30} />}
            placeholder="Tapedin ID"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          <Input
            icon={<BiLockAlt size={30} />}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            style={{
              marginTop: 10,
            }}
          />
          <div className="authAlert">
            <p>
              If you forgot your password, please{" "}
              <span style={{ textDecoration: "underline" }}>
                contact your team leader
              </span>
            </p>
          </div>
          <Button
            loading={loading}
            onClick={() => handleLogin()}
            title="Sign In"
            style={{ marginTop: 30 }}
          />
        </div>
        <img style={{ width: 40, marginTop: 100 }} src={LogoBlack} />
      </main>
    </div>
  );
}
