import React from "react";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";

import "./styles.css";

export default function SpaceItem({ data, index }) {
  const navigate = useNavigate();
  return (
    <motion.div
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        duration: index / 10,
      }}
      onClick={() => navigate(`/${data.id}`)}
      className="spaceItemContainer"
      style={{
        background: `linear-gradient(.15turn, #eef0f5 40%,${data.color} 140%)`,
      }}
    >
      {data.icon}
      <span>{data.name}</span>
    </motion.div>
  );
}
