import { BiArchive, BiWrench, BiCategory } from "react-icons/bi";

export default [
  {
    id: "dev",
    name: "Development",
    color: "#ff945b",
    icon: <BiWrench size={30} />,
  },
  {
    id: "cms",
    name: "Cms",
    color: "#f7ef81",
    icon: <BiArchive size={30} />,
  },
  {
    id: "contents",
    name: "Contents",
    color: "#ff90a0",
    icon: <BiCategory size={30} />,
  },
];
