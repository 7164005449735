import React from "react";

export default function SectionTitle({ title, description, icon }) {
  return (
    <div>
      <div className="flex flex-row items-center">
        {icon && icon}
        <h1 className={!!icon ? `ml-6` : ""}>{title}</h1>
      </div>
      <h2>{description}</h2>
    </div>
  );
}
