import React from "react";

import { getTextTime } from "../utils/getTextTime";

export default function ItemList({ data, draft, href }) {
  return (
    <a
      href={href}
      target={!draft ? "_blank" : "_self"}
      className={`rounded-2xl hover:opacity-60 transition-all cursor-pointer w-full`}
    >
      <div
        className={`flex flex-row items-center justify-between mt-4 ${
          draft && data?.template && "bg-gray-200 py-4 px-6 rounded-xl"
        }`}
      >
        <div className="flex flex-col">
          <strong className="text-2xl font-semibold">{data.title}</strong>
          <div className="flex flex-row mt-2">
            {!draft &&
              data.tags.map((item) => (
                <span
                  key={item}
                  className="text-sm font-regular mt-2 mr-2 self-start px-10 py-1.5 rounded-lg text-black bg-gray-200"
                >
                  #{item}
                </span>
              ))}
          </div>
          <span
            className={`bg-green self-start py-1 px-8 text-xs mt-2 rounded-full ${
              draft && !data?.template && "bg-primary text-white"
            } ${draft && data?.template && "bg-purple text-white"}`}
          >
            {!draft
              ? "Published"
              : draft && !data?.template
              ? "Draft"
              : "Template"}
          </span>
        </div>
        {!draft && (
          <div className="flex flex-col items-end">
            <span className="text-xl font-semibold">
              {getTextTime(data.date)}
            </span>
            <span className="text-lg">{data.views} Views</span>
          </div>
        )}
      </div>
      {!data?.template && (
        <div
          className="bg-gray-200 mt-4"
          style={{
            height: 1,
          }}
        />
      )}
    </a>
  );
}
