import { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function useGetPermission(permissionTag) {
  const [permission, setPermission] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  const navigate = useNavigate();
  const user = useSelector((selector) => selector.app?.user);

  useEffect(() => {
    if (!hasPermission) {
      getPermission();
    }
  }, [hasPermission]);

  const getPermission = useCallback(() => {
    if (user?.roles.find((item) => item == permissionTag)) {
      setPermission(true);
    } else {
      alert("No permission to access this function!");
      navigate("/");
    }
    setHasPermission(true);
  }, [permissionTag]);

  return permission;
}

export default useGetPermission;
