import React from "react";

import { useNavigate, useLocation } from "react-router-dom";

import Button from "./Button";

export default function Menu({ options = [], origin = "" }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="w-71 mt-4 bg-gray-200 p-4 rounded-xl h-[70vh] overflow-y-visible">
      {options.map((item, index) => (
        <Button
          key={String(index)}
          title={item.title}
          onClick={() => navigate(`${location.pathname}${item.path}`)}
          containerStyle={`w-60 `}
          disabled={location.pathname == `${origin}${item.path}`}
        />
      ))}
    </div>
  );
}
