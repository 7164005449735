import React, { useEffect, useState } from "react";

import moment from "moment";

import { BiDotsHorizontalRounded } from "react-icons/bi";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import Header from "../../../components/Header";
import SectionTitle from "../../../components/SectionTitle";
import Button from "../../../components/Button";

import { db } from "../../../config/database";
import ItemList from "../../../components/ItemList";

import useGetPermission from "../../../hooks/useGetPermission";
import { organizeList } from "../../../utils/getArticles";

export default function Create() {
  const permission = useGetPermission("cms");
  const navigate = useNavigate();

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (permission) {
      getArticles();
    }
  }, [permission]);

  async function getArticles() {
    try {
      const articleRef = await getDocs(
        query(
          collection(db, "cms/blog/drafts"),
          orderBy("date", "desc"),
          orderBy("template", "desc")
        )
      );
      const articlesData = organizeList(articleRef.docs);
      setArticles(articlesData);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  return (
    <>
      <div>
        <Header onBack={() => navigate("/cms")} />
        <main>
          <SectionTitle title="Drafs" description="Artigos em rascunhos" />
          <div className="mt-12">
            {articles.map((item) => (
              <div className="flex flex-row items-center justify-between w-[100%]">
                <ItemList
                  key={item?.id}
                  data={item}
                  draft
                  href={`/cms/create?draftId=${item.id}`}
                />
                {!item?.template && (
                  <BiDotsHorizontalRounded
                    className="cursor-pointer"
                    size={30}
                  />
                )}
              </div>
            ))}
          </div>
        </main>
      </div>
    </>
  );
}
