import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyC10KmHAPTv_kYDP1Rl2bX9kiQAcm2oWbc",
  authDomain: "tapedin-space-7b9b1.firebaseapp.com",
  projectId: "tapedin-space-7b9b1",
  storageBucket: "tapedin-space-7b9b1.appspot.com",
  messagingSenderId: "975547452696",
  appId: "1:975547452696:web:346c89dc660261fb5ba26d",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
