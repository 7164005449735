import React, { useEffect, useState } from "react";

import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import SectionTitle from "../../components/SectionTitle";
import Button from "../../components/Button";
import ItemList from "../../components/ItemList";

import { organizeList } from "../../utils/getArticles";
import { db } from "../../config/database";
import spacesOptions from "../../constants/spacesOptions";
import useGetPermission from "../../hooks/useGetPermission";

export default function Cms() {
  const permission = useGetPermission("cms");
  const navigate = useNavigate();

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    if (permission) {
      getArticles();
    }
  }, [permission]);

  async function getArticles() {
    try {
      const articleRef = await getDocs(
        query(collection(db, "cms/blog/articles"), orderBy("date", "desc"))
      );
      const articlesData = organizeList(articleRef.docs);
      setArticles(articlesData);
    } catch (error) {
      alert(error);
    }
  }

  const spaceItem = spacesOptions.find((item) => item.id == "cms");

  return (
    <Layout>
      <Header onBack />
      <main>
        <div className="flex flex-row items-center justify-between">
          <SectionTitle
            title="Cms"
            icon={spaceItem.icon}
            description="Todos os conteúdos e artigos."
          />
          <div className="flex flex-row">
            <Button
              onClick={() => navigate("/cms/drafts")}
              title="Drafts"
              containerStyle="w-40 mr-4 bg-white"
            />
            <Button
              onClick={() => navigate("/cms/create")}
              title="New Article"
              containerStyle="w-60"
            />
          </div>
        </div>
        {articles && <span>Total de artigos: {articles.length}</span>}
        <div className="mt-12">
          {articles.map((item) => (
            <ItemList
              key={item.id}
              data={item}
              href={`https://tapedin.com.br/blog/${item.id}`}
            />
          ))}
        </div>
      </main>
    </Layout>
  );
}
