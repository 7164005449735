import React, { useMemo } from "react";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import SectionTitle from "../../components/SectionTitle";
import Menu from "../../components/Menu";

import useGetPermission from "../../hooks/useGetPermission";
import spacesOptions from "../../constants/spacesOptions";

const options = [
  {
    title: "Publish new version",
    path: "/publish",
  },
];

export default function Dev() {
  const permission = useGetPermission("dev");
  const spaceItem = useMemo(
    () => spacesOptions.find((item) => item.id == "dev"),
    []
  );

  return (
    <Layout>
      <Header onBack />
      <main>
        <SectionTitle
          title="Dev"
          icon={spaceItem.icon}
          description="Gerencie e controle versões"
        />
        <section className="flex flex-row">
          <Menu origin="/dev" options={options} />
        </section>
      </main>
    </Layout>
  );
}
